// Tailwind
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    box-sizing: inherit;
}

body { 
    @apply bg-black flex font-body content-center h-screen items-center overflow-hidden;

    > h1 {
        @apply font-bold fixed top-0 left-0 mx-auto p-12 text-3xl md:text-6xl text-white text-left uppercase;
    }

    > button {
        @apply font-bold fixed top-12 md:top-20 left-0 mx-auto p-12 text-sm md:text-lg text-white text-center uppercase;
    }

    > p {
        @apply font-normal fixed bottom-0 mx-auto p-12 right-0 text-sm text-white;

        a {
            @apply underline hover:no-underline;
        }
    }
}

.statcounter {
    @apply font-normal fixed bottom-0 mx-auto p-12 left-0 text-sm text-white;
}

.main {
    @apply relative my-0 mx-auto;

    width: 266px;

    @screen md {
        @media screen and (min-height: 640px) {
            transform: scale(2.4);
        }
    }

    button {
        @apply absolute cursor-pointer font-semibold rounded-md text-white z-20 h-16 inset-x-0 mx-auto w-10;
    
        animation: pulse 2s infinite;
        top: 43%;
    
        > svg {
            @apply absolute top-0;
    
            filter: drop-shadow(0 2px 10px #000);
            transform: scale(1.4);
        }
    
        > span {
            @apply absolute inset-x-0 top-0 z-10 block font-bold mx-auto py-1 w-full;

            transform: scale(1.4);
    
            span {
                @apply block mx-auto text-xxs uppercase w-full;

                &:nth-of-type(2) {
                    @apply mt-0.5 -mb-0.5;
                }
            }
        }
    } 

    > div {
        @apply border-8 border-white overflow-hidden relative rounded-full;
    
        height: 250px;
        transform: rotate(0deg);
        width: 250px;
    
        > div:first-child {
            @apply h-full w-full;
    
            transition: all 6s cubic-bezier(0,.99,.44,.99);	
            transform-origin: center center;
    
            > div {
                @apply absolute border-solid h-0 opacity-100 w-0;
            
                border-width: 130px 75px 0;
                border-color: #19c transparent;
                transform-origin: 75px 129px;
                left: 50px;
                top: -4px;	
            
                &:nth-child(1){
                    border-color: #61d23f transparent;	
                    transform: rotate(60deg);
                }
                
                &:nth-child(2){
                    border-color: #fbe14c transparent;	
                    transform: rotate(120deg);
                }
                
                &:nth-child(3){
                    border-color: #f19a50 transparent;	
                    transform: rotate(180deg);
                }
                
                &:nth-child(4){
                    border-color: #e93223 transparent;	
                    transform: rotate(240deg);
                }
                
                &:nth-child(5){
                    border-color: #1b124f transparent;	
                    transform: rotate(300deg);
                }
                
                &:nth-child(6){
                    border-color: #459fe9 transparent;	
                    transform: rotate(360deg);
                }
    
                p {
                    @apply absolute mx-auto right-0 text-sm uppercase text-center;
    
                    height: 20px;
                    left: -50px;
                    top: -110px;
                    transform: scale(-1, -1);
                    width: 100px;
                }
            }
        }
    
        > div:last-child {
            @apply absolute left-0 opacity-10 top-0;
    
            background: radial-gradient(ellipse at center,  rgba(255,255,255,1) 0%,rgba(255,255,255,0.99) 1%,rgba(255,255,255,0.91) 9%,rgba(255,255,255,0) 100%); /* W3C */
            height:250px;
            width:250px;
        }
    }
}

@keyframes pulse {
    0% {
        transform: scale3d(1, 1, 1);
    }

    50% {
        transform: scale3d(1.09, 1.09, 1.09);
    }

    100% {
        transform: scale3d(1, 1, 1);
    }
} 

span.cursor {
    display: inline-block;
    background-color: #459fe9;
    margin-left: 0.1rem;
    width: 3px;
    animation: blink 1s infinite;
  }
  
  span.cursor.typing {
    animation: none;
  }
  
  @keyframes blink {
    0%  { background-color: #459fe9; }
    49% { background-color: #459fe9; }
    50% { background-color: transparent; }
    99% { background-color: transparent; }
    100%  { background-color: #459fe9; }
  }